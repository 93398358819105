import React, { Component } from 'react'
import ARMRVR from '../components/ARMRVR'
import Layout from '../layouts/Layout'

export default class ar extends Component {
    render() {
        return (
            <Layout>
                <ARMRVR />
            </Layout>
                
        )
    }
}
